<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
        <Search />
      </v-col>
      <!-- Content -->
      <v-col align="center" class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-card class="full-fill">
          <!-- Edit View -->
          <v-col v-if="isEditing" class="full-fill">
            <!-- Header -->
            <v-row class="ma-0 pa-5">
              <!-- Main Photo -->
              <v-col class="col-12 col-sm-3 ma-0 pa-0 pr-0 pr-sm-3">
                <v-card class="pa-5">
                  <v-file-input
                    v-model="mainImageFile"
                    @change="previewMainImage"
                    label="Main Story Image"
                    filled
                    prepend-icon="mdi-camera"
                    accept="image/*"
                  ></v-file-input>
                  <v-img v-if="mainImageURL || storyData.mainImageURL" :src="mainImageURL || storyData.mainImageURL" class="w-100"></v-img>
                </v-card>
              </v-col>
              <!-- Names -->
              <v-col align-self="center" class="col-12 col-sm-9 ma-0 mt-5 mt-sm-0 pa-0 pl-0 pl-sm-3">
                <v-card class="grey lighten-5 pa-5">
                  <v-card-title class="ma-0 mb-5 pa-0">Names</v-card-title>
                  <v-card class="mt-5 white pa-5">
                    <v-row class="flex-wrap ma-0 pa-0">
                      <v-col class="col-12 col-sm-6 ma-0 pa-0 pr-sm-3">
                        <v-text-field v-model="inputData.firstName" label="First Name"></v-text-field>
                      </v-col>
                      <v-col class="col-12 col-sm-6 ma-0 pa-0 pl-sm-3">
                        <v-text-field v-model="inputData.lastName" label="Last Name"></v-text-field>
                      </v-col>
                      <v-col class="col-12 col-sm-6 ma-0 pa-0 pr-sm-3">
                        <v-text-field v-model="inputData.middleName" label="Middle Name"></v-text-field>
                      </v-col>
                      <v-col class="col-12 col-sm-6 ma-0 pa-0 pl-sm-3">
                        <v-text-field v-model="inputData.maidenName" label="Maiden Name"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <!-- Biography -->
            <v-row v-if="isEditing" class="flex-wrap ma-0 pa-5 pt-0">
              <v-col align="left" class="col-12 col-sm-6 ma-0 pa-0 pr-sm-3">
                <!-- Basic Information -->
                <v-card tile class="grey lighten-5 mb-5 pa-5">
                  <v-card-title class="ma-0 mb-5 pa-0">Basic Information</v-card-title>
                  <v-card class="mt-5 pa-5 white">
                    <v-text-field v-model="inputData.nickname" label="Nickname"></v-text-field>
                    <v-text-field v-model="inputData.dob" label="Born" :type="'date'" :max="new Date().toISOString().split('T')[0]"></v-text-field>
                    <v-text-field v-model="inputData.dod" label="Deceased" :type="'date'" :max="new Date().toISOString().split('T')[0]"></v-text-field>
                    <v-select v-model="inputData.gender" label="Gender" :items="['Male', 'Female', 'Non-Binary']"/>
                    <v-select v-if="!inputData.isServiceAnimal" v-model="inputData.race" label="Heritage" :items="require('@/assets/data/races.json')"/>
                    <v-select v-if="inputData.isServiceAnimal" v-model="inputData.species" label="Species" :items="['Dog', 'Cat', 'Other']"/>
                    <v-text-field v-if="inputData.isServiceAnimal" v-model="inputData.breed" label="Breed" :type="'text'"></v-text-field>
                    <v-checkbox v-model="inputData.isServiceAnimal" label="This veteran is a service animal."></v-checkbox>
                    <v-text-field v-model="inputData.homeTown" label="Home Town" :type="'text'"></v-text-field>
                  </v-card>
                </v-card>
                <!-- Details -->
                <v-card tile class="grey lighten-5 pa-5">
                  <v-card-title class="ma-0 mb-5 pa-0">Details</v-card-title>
                  <v-card class="mt-5 white pa-5">
                    <v-textarea v-model="inputData.details" label="Details"></v-textarea>
                  </v-card>
                </v-card>
              </v-col>
              <v-col align="left" class="col-12 col-sm-6 ma-0 pa-0 pl-sm-3 pt-5 pt-sm-0">
                <!-- Education -->
                <v-card class="grey lighten-5 mb-5 pa-5">
                  <v-card-title class="ma-0 mb-5 pa-0">Education</v-card-title>
                  <v-card v-for="(entry, index) in inputData.education" :key="entry.key" class="mt-5 white pa-5">
                    <v-text-field v-model="entry.school" label="School"></v-text-field>
                    <!-- <div v-if="index>0" @click="removeInputEducation(index)"><i class="far fa-window-close"></i></div> -->
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="entry.yearStart" label="Year Start" type="number"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="entry.yearEnd" label="Year End" type="number"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field v-model="entry.areaStudy" label="Area of Study"></v-text-field>
                    <v-text-field v-model="entry.details" label="Details"></v-text-field>
                    <v-btn @click="removeInputEducation(index)" class="red darken-1 mt-5 white--text">
                      <i class="fas fa-trash"></i>
                    </v-btn>
                  </v-card>
                  <v-btn @click="addInputEducation" class="blue darken-1 mt-5 white--text">
                    + Add Education
                  </v-btn>
                </v-card>
                <!-- Service Terms -->
                <v-card class="grey lighten-5 pa-5">
                  <v-card-title class="ma-0 mb-5 pa-0">Service Terms</v-card-title>
                  <v-card v-for="(entry, index) in inputData.serviceTerms" :key="entry.key" class="mt-5 white pa-5">
                    <v-select v-model="entry.branch" label="Military Branch" :items="require('@/assets/data/branches.json')" return-object/>
                    <v-select v-model="entry.rank" label="Highest Grade/Rank" :items="!entry.branch ? [] : require(`@/assets/data/${entry.branch.rankPath}/ranks.json`)" return-object/>
                    <!-- <img v-if="index>0" class="pointer self-center w2" @click="value.splice(index, 1)" :src="require('@/assets/img/exit.png')"> -->
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="entry.servedFrom" label="Year Start" type="number"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="entry.servedTo" label="Year End" type="number"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field v-model="entry.bootCamp" label="Boot Camp Location"></v-text-field>
                    <v-select v-model="entry.specialty" label="Specialty" :items="!entry.branch ? [] : require(`@/assets/data/${entry.branch.rankPath}/specialties.json`)" return-object/>
                    <v-text-field v-model="entry.deployments" label="Deployments"></v-text-field>
                    <v-text-field v-if="storyData.dod" v-model="entry.casType" label="Casualty Type"></v-text-field>
                    <v-text-field v-if="storyData.dod" v-model="entry.casLoc" label="Casualty Location"></v-text-field>
                    <v-text-field v-model="entry.details" label="Details"></v-text-field>
                    <v-btn v-if="index > 0" @click="removeInputServiceTerm(index)" class="red darken-1 mt-5 white--text">
                      <i class="fas fa-trash"></i>
                    </v-btn>
                  </v-card>
                  <v-btn class="blue darken-1 mt-5 white--text" @click="addInputServiceTerm">
                    + Add Service Term
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <!-- Controls -->
            <v-row justify="center" class="ma-0 pa-5 pt-0">
              <v-btn
                @click="submitEditStory"
                :loading="loading" 
                class="blue darken-3 mr-10 white--text"
              >
                Submit
              </v-btn>
              <v-btn
                @click="isEditing=false"
                :disabled="loading"
                class="blue darken-3 white--text"
              >
                Cancel
              </v-btn>
              <div v-if="error" class="error--text mt3">{{ error }}</div>
            </v-row>
          </v-col>
          <!-- Read View -->
          <v-col class="full-fill" v-else>
            <!-- Header -->
            <v-row align="center" class="bioHeaderGradient ma-0 pa-5">
              <!-- Main Photo -->
              <v-col class="col-12 col-sm-3 ma-0 pa-0">
                <v-card class="ma-0 pa-2">
                  <v-img v-if="storyData.mainImageURL" :src="storyData.mainImageURL" class="w-100"></v-img>
                  <span v-else style="color: gray;"><i class="fas fa-user fa-5x pa-10"></i></span>
                </v-card>
              </v-col>
              <!-- Reactions -->
              <v-col @click="isShowingReactToStoryModal=true; setSelectedReaction(selectedReaction)" class="align-center align-sm-start col-12 col-sm-1 d-flex flex-row flex-sm-column ma-0 pa-0 pl-sm-5 pt-5 pt-sm-0" style="cursor: pointer;">
                <v-row justify="center" class="ma-0 pa-0 pb-sm-4">
                  <v-img max-width="24" src="@/assets/img/reaction-thumbsup2.png" class="mr-2"></v-img>
                  <div class="white--text">{{ storyData.reactions ? storyData.reactions['thumbsup'] ? storyData.reactions['thumbsup'].length : '0' : '0' }}</div>
                </v-row>
                <v-row justify="center" class="ma-0 pa-0 pb-sm-4">
                  <v-img max-width="24" src="@/assets/img/reaction-salute2.png" class="mr-2"></v-img>
                  <div class="white--text">{{ storyData.reactions ? storyData.reactions['salute'] ? storyData.reactions['salute'].length : '0' : '0' }}</div>
                </v-row>
                <v-row justify="center" class="ma-0 pa-0 pb-sm-4">
                  <v-img max-width="24" src="@/assets/img/reaction-flag2.png" class="mr-2"></v-img>
                  <div class="white--text">{{ storyData.reactions ? storyData.reactions['flag'] ? storyData.reactions['flag'].length : '0' : '0' }}</div>
                </v-row>
                <v-row justify="center" class="ma-0 pa-0 pb-sm-4">
                  <v-img max-width="24" src="@/assets/img/reaction-hand-heart2.png" class="mr-2"></v-img>
                  <div class="white--text">{{ storyData.reactions ? storyData.reactions['handheart'] ? storyData.reactions['handheart'].length : '0' : '0' }}</div>
                </v-row>
                <v-row justify="center" class="ma-0 pa-0">
                  <v-img max-width="24" src="@/assets/img/reaction-clap2.png" class="mr-2"></v-img>
                  <div class="white--text">{{ storyData.reactions ? storyData.reactions['clap'] ? storyData.reactions['clap'].length : '0' : '0' }}</div>
                </v-row>
                <v-dialog v-model="isShowingReactToStoryModal" width="500">
                  <v-card>
                    <v-card-title>React to Story</v-card-title>
                    <v-card-text>Show your respects to this veteran by reacting to their story.</v-card-text>
                    <v-row class="ma-0 pa-5" justify="center">
                      <v-col align="center" @click="setSelectedReaction('thumbsup')" class="reaction-icon" :class="{ 'bg-blue': selectedReaction==='thumbsup' }">
                        <v-img max-width="32" src="@/assets/img/reaction-thumbsup.png"></v-img>
                        <div class="pt-3">{{ storyData.reactions ? storyData.reactions['thumbsup'] ? storyData.reactions['thumbsup'].length : '0' : '0' }}</div>
                      </v-col>
                      <v-col align="center" @click="setSelectedReaction('salute')" class="reaction-icon" :class="{ 'bg-blue': selectedReaction==='salute' }">
                        <v-img max-width="32" src="@/assets/img/reaction-salute.png"></v-img>
                        <div class="pt-3">{{ storyData.reactions ? storyData.reactions['salute'] ? storyData.reactions['salute'].length : '0' : '0' }}</div>
                      </v-col>
                      <v-col align="center" @click="setSelectedReaction('flag')" class="reaction-icon" :class="{ 'bg-blue': selectedReaction==='flag' }">
                        <v-img max-width="32" src="@/assets/img/reaction-flag.png"></v-img>
                        <div class="pt-3">{{ storyData.reactions ? storyData.reactions['flag'] ? storyData.reactions['flag'].length : '0' : '0' }}</div>
                      </v-col>
                      <v-col align="center" @click="setSelectedReaction('handheart')" class="reaction-icon" :class="{ 'bg-blue': selectedReaction==='handheart' }">
                        <v-img max-width="32" src="@/assets/img/reaction-hand-heart.png"></v-img>
                        <div class="pt-3">{{ storyData.reactions ? storyData.reactions['handheart'] ? storyData.reactions['handheart'].length : '0' : '0' }}</div>
                      </v-col>
                      <v-col align="center" @click="setSelectedReaction('clap')" class="mr-0 reaction-icon" :class="{ 'bg-blue': selectedReaction==='clap' }">
                        <v-img max-width="32" src="@/assets/img/reaction-clap.png"></v-img>
                        <div class="pt-3">{{ storyData.reactions ? storyData.reactions['clap'] ? storyData.reactions['clap'].length : '0' : '0' }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-5" justify="center">
                      <v-col v-if="selectedReaction==='thumbsup'" align="center">Give a thumbs-up to this story as a display of appreciation.</v-col>
                      <v-col v-if="selectedReaction==='salute'" align="center">Salute this veteran, showing your respect as a fellow servicemember.</v-col>
                      <v-col v-if="selectedReaction==='flag'" align="center">Pay your respects to this fallen servicemember with the folded American flag.</v-col>
                      <v-col v-if="selectedReaction==='handheart'" align="center">Place your hand over your heart to show a solemn reaction to this story.</v-col>
                      <v-col v-if="selectedReaction==='clap'" align="center">Cheer for this servicemember and their story by clapping.</v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn v-if="!isSelectedReactionAdded"
                        @click="addSelectedReaction"
                        class="blue darken-3 white--text"
                        :loading="loading"
                      >
                        Add Reaction
                      </v-btn>
                      <v-btn v-else
                        @click="removeSelectedReaction"
                        class="blue darken-3 white--text"
                        :loading="loading"
                      >
                        Remove Reaction
                      </v-btn>
                      <v-btn
                        :disabled="loading"
                        @click="isShowingReactToStoryModal=false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <!-- Names -->
              <v-col class="col-12 col-sm-7 ma-0 pa-0 pa-sm-0 pt-5">
                <h1 class="white--text">{{ rankString }}</h1>
                <h1 class="white--text">{{ storyData.firstName }} {{ storyData.middleName }} {{ storyData.nickname ? ('"' + storyData.nickname + '"') : '' }}</h1>
                <h1 class="white--text">{{ storyData.lastName }} {{ storyData.maidenName ? '(' + storyData.maidenName + ')' : '' }}</h1>
              </v-col>
              <!-- Controls -->
              <v-col class="align-center align-sm-end col-12 col-sm-1 d-flex flex-row flex-sm-column ma-0 pa-0 pt-5 pt-sm-0">
                <!-- Share Story -->
                <v-row justify="center" class="ma-0 pa-0" style="cursor: pointer;">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="isShowingShareStoryModal=true" v-on="on" v-bind="attrs" class="fas fa-share-alt pb-3 pl-sm-10 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Share Story</span>
                  </v-tooltip>
                </v-row>
                <v-dialog v-model="isShowingShareStoryModal" width="500">
                  <v-card>
                    <v-card-title class="mb-3">Share Story</v-card-title>
                    <v-card-subtitle class="mb-3">Share this veteran's story on social media sites.</v-card-subtitle>
                    <v-col class="ma-0 pa-0" align="center">
                      <div class="mb-3">
                        <a :href="`https://www.facebook.com/sharer/sharer.php?u=honor-tag.com/#/story-view?storyID=${storyID}`" target="_blank" style="text-decoration: none;">
                          <v-btn
                            class="blue darken-3 white--text"
                            :loading="loading"
                          >
                            <i class="fab fa-facebook-f mr-3"></i>Facebook
                          </v-btn>
                        </a>
                      </div>
                      <div class="mb-3 w100">
                        <a :href="`https://twitter.com/intent/tweet?text=I'm sharing this veteran's story on honor-tag.com.`" target="_blank" style="text-decoration: none;">
                          <v-btn
                            class="blue darken-3 white--text w-100"
                            :loading="loading"
                          >
                            <i class="fab fa-twitter mr-3"></i>Twitter
                          </v-btn>
                        </a>
                      </div>
                      <div class="mb-3 w100">
                        <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=honor-tag.com/#/story-view?storyID=${storyID}`" target="_blank" style="text-decoration: none;">
                          <v-btn
                            class="blue darken-3 white--text"
                            :loading="loading"
                          >
                            <i class="fab fa-linkedin mr-3"></i>LinkedIn
                          </v-btn>
                        </a>
                      </div>
                      <div class="mb-3 w100">
                        <a :href="`http://pinterest.com/pin/create`" target="_blank" style="text-decoration: none;">
                          <v-btn
                            class="blue darken-3 white--text"
                            :loading="loading"
                          >
                            <i class="fab fa-pinterest mr-3"></i>Pinterest
                          </v-btn>
                        </a>
                      </div>
                      <v-btn class="mb-3 mt-3"
                        :disabled="loading"
                        @click="isShowingShareStoryModal=false"
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                  </v-card>
                </v-dialog>
                <!-- Favorite / Unfavorite Story -->
                <v-row v-if="userData.favoriteStories && userData.favoriteStories.indexOf(storyID) > -1" justify="center" class="ma-0 pa-0" style="cursor: pointer;">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="unfavoriteStory" v-on="on" v-bind="attrs" class="fas fa-star ml-2 pb-3 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Unfavorite Story</span>
                  </v-tooltip>
                </v-row>
                <v-row v-else justify="center" class="ma-0 pa-0" style="cursor: pointer;">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="favoriteStory" v-on="on" v-bind="attrs" class="far fa-star pb-3 pl-5 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Favorite Story</span>
                  </v-tooltip>
                </v-row>
                <!-- Manage Story -->
                <v-row v-if="isAuth && !storyData.manager" justify="center" class="ma-0 pa-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="isShowingManageStoryModal=true" v-on="on" v-bind="attrs" class="fas fa-plus pb-3 pl-5 pl-sm-10 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Manage Story</span>
                  </v-tooltip>
                </v-row>
                <v-dialog v-model="isShowingManageStoryModal" width="500">
                  <v-card>
                    <v-card-title>Manage Story</v-card-title>
                    <v-card-text>Take control of this story as its curator. When someone changes something within the story, you will be notified via email.</v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="isShowingManageStoryModal=false; manageStory()"
                        :loading="loading"
                        color="red white--text"
                      >
                        <i class="fas fa-plus mr-2" style="color: white;"></i>
                        Manage Story
                      </v-btn>
                      <v-btn
                        :disabled="loading"
                        @click="isShowingManageStoryModal=false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Stop Managing Story -->
                <v-row v-if="isAuth && storyData.manager===userUID" justify="center" class="ma-0 pa-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="isShowingStopManagingStoryModal=true" v-on="on" v-bind="attrs" class="fas fa-minus pb-3 pl-5 pl-sm-10 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Stop Managing Story</span>
                  </v-tooltip>
                </v-row>
                <v-dialog v-model="isShowingStopManagingStoryModal" width="500">
                  <v-card>
                    <v-card-title>Stop Managing Story</v-card-title>
                    <v-card-text>Are you sure you want to stop managing this story? You will no longer receive email notifications when it changes.</v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="isShowingStopManagingStoryModal=false; stopManagingStory()"
                        :loading="loading"
                        color="red white--text"
                      >
                        <i class="fas fa-minus mr-2" style="color: white;"></i>
                        Stop Managing Story
                      </v-btn>
                      <v-btn
                        :disabled="loading"
                        @click="isShowingStopManagingStoryModal=false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Delete Story Modal -->
                <v-row v-if="isAuth && storyData.manager===userUID" justify="center" class="ma-0 pa-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="isShowingDeleteStoryModal=true" v-on="on" v-bind="attrs" class="fas fa-trash pb-3 pl-5 pl-sm-10 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Delete Story</span>
                  </v-tooltip>
                </v-row>
                <v-dialog v-model="isShowingDeleteStoryModal" width="500">
                  <v-card>
                    <v-card-title>Delete Story</v-card-title>
                    <v-card-text>Are you sure you want to delete this story?</v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="isShowingDeleteStoryModal=false; deleteStory()"
                        :loading="loading"
                        color="red white--text"
                      >
                        <i class="fas fa-trash-alt mr-2" style="color: white;"></i>
                        Delete
                      </v-btn>
                      <v-btn
                        :disabled="loading"
                        @click="isShowingDeleteStoryModal=false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Flag Story Modal -->
                <v-row justify="center" class="ma-0 pa-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span @click="showFlagStoryModal" v-on="on" v-bind="attrs" class="fas fa-flag pb-3 pl-5 pl-sm-10 pr-0 pt-3" style="color: white; cursor: pointer;"></span>
                    </template>
                    <span>Flag Story</span>
                  </v-tooltip>
                </v-row>
                <v-dialog v-model="isShowingFlagStoryModal" width="500">
                  <v-card>
                    <v-card-title>Flag Story</v-card-title>
                    <div v-if="!isFlaggingSuccess">
                      <v-card-text class="pb-0">
                        Honor Tag values the integrity of the content on the site. If you feel you have found erroneous or inappropriate content, please let us know.
                      </v-card-text>
                      <v-card-actions class="mt-0">
                        <v-col class="mt-0">
                          <v-checkbox v-model="flagReasons" label="Duplicate Story" value="duplicate" dense></v-checkbox>
                          <v-checkbox v-model="flagReasons" label="Inappropriate Content (nudity or other explicit content)" value="inappropriate" dense></v-checkbox>
                          <v-checkbox v-model="flagReasons" label="Hate Speech" value="hate" dense></v-checkbox>
                          <v-checkbox v-model="flagReasons" label="Incorrect Information" value="incorrect" dense></v-checkbox>
                          <v-checkbox v-model="flagReasonOther" label="Other (please describe below)" value="other" dense></v-checkbox>
                          <v-textarea v-model="flagReasonOtherDetails" :disabled="!flagReasonOther" label="Please describe the issue..." rows="3"></v-textarea>
                          <v-btn
                            @click="flagStory()"
                            :loading="loading"
                            color="red white--text"
                            class="mr-3"
                          >
                            <i class="fas fa-flag mr-2" style="color: white;"></i>
                            Submit
                          </v-btn>
                          <v-btn
                            :disabled="loading"
                            @click="isShowingFlagStoryModal=false"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </div>
                    <div v-else>
                      <v-card-text class="pb-0">
                        Your feedback has been sent to the owner of this story. They will receive an email shortly. Thank you for your help in keeping Honor Tag a safe place for our community.
                      </v-card-text>
                      <v-card-actions class="mt-0">
                        <v-col class="mt-0">
                          <v-btn
                            @click="isShowingFlagStoryModal=false"
                          >
                            Close
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <!-- Tabs -->
            <v-row class="ma-0 pa-5">
              <v-tabs v-model="tab" fixed-tabs>
                <v-tab>Biography</v-tab>
                <v-tab>Photo Album</v-tab>
                <v-tab>Chapters</v-tab>
                <v-tab>Shadowbox</v-tab>
              </v-tabs>
            </v-row>
            <!-- Tab Content -->
            <v-row class="ma-0 pa-5 pt-0">
              <v-tabs-items v-model="tab" class="full-fill">
                <!-- Biography -->
                <v-tab-item class="full-fill">
                  <v-row class="flex-wrap full-fill">
                    <v-col align="left" class="col-12 col-sm-6 ma-0 pa-1 pr-sm-3">
                      <!-- Basic Information -->
                      <v-card tile class="blue lighten-5 mb-5 pa-5">
                        <v-card-title class="ma-0 mb-5 pa-0" style="word-break: keep-all">Basic Information</v-card-title>
                        <div class="mb-5">
                          <h3>Nickname</h3>
                          <div class="mb-5 ml-5">{{ storyData.nickname || 'Not entered' }}</div>
                          <h3>Born</h3>
                          <div class="mb-5 ml-5">{{ storyData.dob || 'Not entered' }}</div>
                          <h3>Deceased</h3>
                          <div class="mb-5 ml-5">{{ storyData.dod || 'Not entered' }}</div>
                          <h3>Gender</h3>
                          <div class="mb-5 ml-5">{{ storyData.gender || 'Not entered' }}</div>
                          <h3 v-if="!storyData.isServiceAnimal">Heritage</h3>
                          <div v-if="!storyData.isServiceAnimal" class="mb-5 ml-5">{{ storyData.race || 'Not entered' }}</div>
                          <h3 v-if="storyData.isServiceAnimal">Species</h3>
                          <div v-if="storyData.isServiceAnimal" class="mb-5 ml-5">{{ storyData.species || 'Not entered' }}</div>
                          <h3 v-if="storyData.isServiceAnimal">Breed</h3>
                          <div v-if="storyData.isServiceAnimal" class="mb-5 ml-5">{{ storyData.breed || 'Not entered' }}</div>
                          <h3>Home Town</h3>
                          <div class="mb-5 ml-5">{{ storyData.homeTown || 'Not entered' }}</div>
                        </div>
                      </v-card>
                      <!-- Details -->
                      <v-card tile class="blue lighten-5 pa-5">
                        <v-card-title class="ma-0 mb-5 pa-0">Details</v-card-title>
                        {{ storyData.details || 'Not entered' }}
                      </v-card>
                    </v-col>
                    <v-col align="left" class="col-12 col-sm-6 ma-0 mt-3 mt-sm-0 pa-1 pl-sm-3">
                      <!-- Service Terms -->
                      <v-card tile class="blue lighten-5 mb-5 pa-5">
                        <v-card-title class="ma-0 mb-5 pa-0">Service Terms</v-card-title>
                        <div v-for="entry in storyData.serviceTerms" :key="entry.key" class="mb-5 ml-5">
                          <div v-if="entry.branch"><b>{{ entry.branch.text }}</b></div>
                          <div v-if="entry.servedFrom||entry.servedTo">{{ entry.servedFrom || '--' }} to {{ entry.servedTo || '' }}</div>
                          <div v-if="entry.rank">Rank: {{ entry.rank.text }}</div>
                          <div v-if="entry.specialty">Specialty: {{ entry.specialty.text }}</div>
                          <div v-if="entry.bootCamp">Boot Camp: {{ entry.bootCamp }}</div>
                          <div v-if="entry.deployments">Deployments: {{ entry.deployments }}</div>
                          <div v-if="entry.casType">Casualty Type: {{ entry.casType }}</div>
                          <div v-if="entry.casLoc">Casualty Location: {{ entry.casLoc }}</div>
                          <div v-if="entry.details">Details: {{ entry.details }}</div>
                        </div>
                      </v-card>
                      <!-- Education -->
                      <v-card tile class="blue lighten-5 pa-5">
                        <v-card-title class="ma-0 mb-5 pa-0">Education</v-card-title>
                        <div v-for="entry in storyData.education" :key="entry.key" class="mb-5">
                          <div><b>{{ entry.school }}</b></div>
                          <div>{{ entry.yearStart ? entry.yearStart : '--' }} to {{ entry.yearEnd ? entry.yearEnd : '' }}</div>
                          <div v-if="entry.areaStudy">Area of Study: {{ entry.areaStudy }}</div>
                          <div v-if="entry.details">Details: {{ entry.details }}</div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Controls -->
                  <v-row v-if="storyData.manager===userUID" justify="center" class="ma-0 mt-5 pa-0">
                    <v-btn
                      @click="isEditing = true; resetInputData()"
                      class="blue darken-3 white--text"
                    >
                      Edit Story
                    </v-btn>
                    <div v-if="error" class="error--text mt3">{{ error }}</div>
                  </v-row>
                </v-tab-item>
                <!-- Photo Album -->
                <v-tab-item class="full-fill">
                  <PhotoAlbumTiled :storyID="storyID" :storyData="storyData" :isEditable="storyData.manager===userUID" class="w-100"/>
                </v-tab-item>
                <!-- Chapters -->
                <v-tab-item class="full-fill">
                  <StoryChapters :storyID="storyID" :chapters="storyData.chapters" class="w-100"/>
                </v-tab-item>
                <!-- Shadowbox -->
                <v-tab-item class="full-fill">
                  <div class="flex flex-column w-100 w-60-l mh3 mh0-ns">
                    <h3 v-if="storyData.manager===userUID" class="mb3">Fill Your Shadowbox</h3>
                    <div v-if="storyData.manager===userUID" class="tc mb3">Choose an item from the list, then add it to your shadowbox. Click on an item to select it and reposition it.</div>
                    <Shadowbox
                      ref="shadowbox"
                      :serviceTerms="storyData.serviceTerms"
                      :awards="storyData.awards"
                      :storyID="storyID"
                      :storyManagerID="storyData.manager"
                      v-on:deleteAward="onDeleteAward"
                      v-on:savePlaque="onSavePlaque"
                      :plaque="storyData.plaque || []"
                      :isDeceased="storyData.dod"
                    />
                    <ShadowboxControls
                      :isDeceased="storyData.dod"
                      :storyManagerID="storyData.manager"
                      v-on:addAward="onAddAward"
                      v-on:viewAwardDetails="onViewAwardDetails"
                      v-on:moveAward="onMoveAward"
                      v-on:toggleFlagPosition="onToggleFlagPosition"
                      v-on:getStoryData="getStoryData"
                      style="width: 75%;"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  // import firebase from 'firebase'
  import ShadowboxControls from '@/components/ShadowboxControls'
  import PhotoAlbumTiled from '@/components/PhotoAlbumTiled'
  import Search from '@/components/Search'
  import StoryChapters from '@/components/StoryChapters'
  import Shadowbox from '@/components/Shadowbox'
  import axios from 'axios'

  export default {
    name: 'StoryView',

    components: {
      PhotoAlbumTiled,
      Search,
      Shadowbox,
      ShadowboxControls,
      StoryChapters
    },

    data () {
      return {
        chapters: [],
        error: '',
        flagReasonOther: '',
        flagReasonOtherDetails: '',
        flagReasons: [],
        flaggingError: false,
        inProgress: false,
        isEditing: false,
        isFlaggingSuccess: false,
        isHidingPhoto: false,
        isSelectedReactionAdded: false,
        isShowingDeleteStoryModal: false,
        isShowingFlagStoryModal: false,
        isShowingManageStoryModal: false,
        isShowingReactToStoryModal: false,
        isShowingShareStoryModal: false,
        isShowingSocialMediaCallout: false,
        isShowingStopManagingStoryModal: false,
        inputData: {
          serviceTerms: [{ }]
        },
        loading: false,
        mainImageFile: null,
        mainImageURL: '',
        photoAlbum: [],
        saved: false,
        saving: false,
        selectedReaction: 'thumbsup',
        storyData: {
          awards: [{ }],
          serviceTerms: [{ }]
        },
        storyID: '',
        width: 200,
        height: 200,
        tab: '',
        top: 200,
        left: 200
      }
    },

    computed: {
      ...mapGetters([
        'isAuth',
        'userData',
        'userUID'
      ]),
      computedYears: function () {
        const years = Array.from({ length: 251 }, (v, i) => i + new Date().getFullYear() - 250).reverse()
        var options = []
        for (var i = 2; i < years.length; i++) {
          options[i] = { text: years[i - 2] }
        }
        return options
      },
      // Grabbing the url with the storyID in it for social sharing component
      socialSharedUrl: function () {
        return process.env.VUE_APP_STORY_VIEW + this.storyID
      },
      isBirthdayToday: function () {
        if (!this.storyData.dob) return false

        const birthMonth = this.storyData.dob.substring(5, 7)
        const birthDay = this.storyData.dob.substring(8, 10)
        const todaysDate = new Date().toISOString().split('T')[0]
        const month = todaysDate.substring(5, 7)
        const day = todaysDate.substring(8, 10)

        return (birthMonth === month && birthDay === day)
      },
      rankString: function () {
        if (this.storyData.serviceTerms[0].rank) {
          var rankStr = this.storyData.serviceTerms[0].rank.text
          var rankStrNoGrade = rankStr.substr(rankStr.indexOf('- ') + 2, rankStr.length) + ' '

          return rankStrNoGrade
        }

        return ''
      }
    },

    watch: {
      mainPhoto: {
        handler: function () {
          this.saved = false
        },
        deep: true
      }
    },

    created: function () {
      this.getStoryData()
    },

    mounted: function () {
      this.isShowingSocialMediaCallout = this.$route.params.isShowingSocialMediaCallout
      if (this.isShowingSocialMediaCallout) this.$modal.show('social-modal')
    },

    methods: {
      addInputEducation () {
        if (!this.inputData.education) this.inputData.education = []
        this.inputData.education.push({})
        this.$forceUpdate()
      },

      addInputServiceTerm () {
        this.inputData.serviceTerms.push({})
        this.$forceUpdate()
      },

      addSelectedReaction() {
        this.resetInputData()

        if (!this.inputData.reactions) this.inputData.reactions = {}
        if (!this.inputData.reactions[this.selectedReaction]) this.inputData.reactions[this.selectedReaction] = []
        this.inputData.reactions[this.selectedReaction].push(this.userUID)

        this.submitEditStory2()
      },

      deleteStory () {
        this.loading = true
        const storyID = this.storyID
        this.$store.dispatch('deleteStory', { storyID })
        .then(() => {
          this.loading = false

          // Route to account page
          this.$router.push('/account')
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      favoriteStory () {
        if (!this.userData.favoriteStories) this.userData.favoriteStories = []
        if (this.userData.favoriteStories.indexOf(this.storyID) > -1) return
        this.userData.favoriteStories.push(this.storyID)
        this.$store.dispatch('updateUserData', { userUID: this.userUID })
        .then(() => {
          this.loading = false
          this.$forceUpdate()
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      flagStory () {
        this.loading = true
        if (this.flagReasons.indexOf('hate') == -1 || this.flagReasons.indexOf('inappropriate') == -1) return
        const { storyID, flagReasons, flagReasonOtherDetails } = this
        const firstName = this.storyData.firstName
        const lastName = this.storyData.lastName
        const storyManager = this.storyData.manager
        axios({
          url: 'https://us-central1-dev-honor-tag-vets.cloudfunctions.net/api/flag',
          data: {
            firstName,
            lastName,
            storyID,
            flagReasons,
            flagReasonOtherDetails,
            storyManager
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST'
        })
        .then((resp) => {
          if (resp.data === 'success') {
            this.isFlaggingSuccess = true
            this.loading = false
          } else {
            console.error(resp)
            this.flaggingError = true
            this.loading = false
          }
        })
        .catch((error) => {
          console.error(error)
          this.flaggingError = true
          this.loading = false
        })
      },

      async getChapterData () {
        /* await firebase
          .firestore()
          .collection('stories')
          .doc(this.storyID)
          .collection('chapters')
          .doc('chapters')
          .get()
          .then((resp) => {
            this.chapters = resp.data() ? resp.data().chapters : []
          })
          .catch((error) => {
            console.error(error)
          }) */
      },

      async getAwardsData () {
        /* await firebase
          .firestore()
          .collection('awards')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.awardsData[doc.id] = doc.data()
            })
          })
          .catch((error) => {
            console.error(error)
          })
        this.fetching = false */
      },

      getStoryData () {
        const storyID = this.storyID = this.$route.query.storyID
        this.$store.dispatch('getStoryData', { storyID })
        .then((resp) => {
          this.storyData = resp

          // Check if this story is in manager's favorites
          
        })
        .catch((err) => {
          this.error = err
        })

        // this.awardsData = {}
        // this.getAwardsData()

        window.scrollTo(0, 0)

        this.$forceUpdate()
      },

      /*  When the story photo is changed, the old photo is currently not being deleted.
          This will need to be added before too long */
      hidePhoto (value) {
        this.isHidingPhoto = value
      },

      manageStory () {
        this.resetInputData()
        this.inputData.manager = this.userUID

        this.submitEditStory2()
      },

      onAddAward (award) {
        if (!this.storyData.awards || this.storyData.awards === undefined) this.storyData.awards = []
        if (!this.$refs.shadowbox.selectAward(award)) {
            this.storyData.awards.push(award)
            this.storyData.awards = [].concat(this.storyData.awards) // components relying on this prop will not update otherwise
            this.$forceUpdate()
            this.submitAwards()
          }
      },

      onDeleteAward (award) {
        var index = this.storyData.awards.indexOf(award)
        if (index > -1) this.storyData.awards.splice(index, 1)
        this.submitAwards()
      },

      onMoveAward (moveLeft) {
        var award = this.$refs.shadowbox.selectedAward
        if (award) {
          var awards = this.storyData.awards
          const awardIndex = awards.indexOf(award)
          var nextIndex
          if (moveLeft) {
            nextIndex = awardIndex === 0 ? awards.length - 1 : awardIndex - 1
          } else {
            nextIndex = awardIndex === awards.length - 1 ? 0 : awardIndex + 1
          }
          const temp = awards[awardIndex]
          awards[awardIndex] = awards[nextIndex]
          awards[nextIndex] = temp
          this.storyData.awards = [].concat(this.storyData.awards) // components relying on this prop will not update otherwise
          this.submitAwards()
        }
      },

      onSavePlaque (plaque) {
        this.resetInputData()
        this.inputData.plaque = plaque

        this.submitEditStory2()
      },
  
      onToggleFlagPosition () {
        this.$refs.shadowbox.toggleFlagPosition()
      },

      onViewAwardDetails () {
        this.$refs.shadowbox.viewAwardDetails()
      },

      previewMainImage () {
        this.mainImageURL = this.mainImageFile ? URL.createObjectURL(this.mainImageFile) : ''
      },

      removeInputEducation (index) {
        this.inputData.education.splice(index, 1)
        this.$forceUpdate()
      },

      removeInputServiceTerm (index) {
        this.inputData.serviceTerms.splice(index, 1)
        this.$forceUpdate()
      },

      removeSelectedReaction() {
        this.resetInputData()

        // If this story has any reactions...
        var reactions
        if (this.inputData.reactions && this.inputData.reactions[this.selectedReaction]) {
          reactions = this.inputData.reactions[this.selectedReaction]
          // If this user already reacted with this reaction, remove the reaction
          const reactionIndex = reactions.indexOf(this.userUID)
          if (reactionIndex > -1) {
            reactions.splice(reactionIndex, 1)
  
            this.submitEditStory2()
          }
        }
      },

      resetInputData () {
        this.inputData = JSON.parse(JSON.stringify(this.storyData))
        
        window.scrollTo(0, 0)
      },

      setSelectedReaction (reaction) {
        this.selectedReaction = reaction

        // If this story has any reactions...
        if (this.storyData.reactions && this.storyData.reactions[this.selectedReaction]) {
          // Check if this user already reacted with this reaction
          this.isSelectedReactionAdded = this.storyData.reactions[this.selectedReaction].indexOf(this.userUID) > -1
        }
      },

      shareStory () {

      },

      showFlagStoryModal () {
        this.isFlaggingSuccess = false
        this.flagReasons = []
        this.flagReasonOther = false
        this.flagReasonOtherDetails = ''
        this.isShowingFlagStoryModal = true
      },

      stopManagingStory () {
        this.resetInputData()
        this.inputData.manager = ''

        this.submitEditStory2()
      },

      submitAwards () {
        this.$store.dispatch('updateStory', { storyID: this.storyID, storyData: { awards: this.storyData.awards, plaque: this.$refs.shadowbox.plaque } })
        .then(() => {
          this.$store.dispatch('getStoryData', { storyID: this.storyID })
          .then((resp) => {
            this.loading = false
            this.storyData = resp
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      submitEditStory () {
        this.error = ''
        if (!this.loading) {
          this.loading = true

          // First, upload the main story image, so we can get the image's download URL and update the story itself with the URL
          if (this.mainImageFile) {
            this.$store.dispatch('uploadFile', ({ file: this.mainImageFile, path: 'storyImages/' + this.storyID + '/mainPhoto/' + this.mainImageFile.name }))
            .then((mainImageURL) => {
              this.inputData.mainImageURL = mainImageURL

              this.submitEditStory2()
            })
            .catch((err) => {
              this.loading = false
              this.error = err
            })
          } else {
            this.submitEditStory2()
          }
        }
      },

      submitEditStory2 () {
        if (this.inputData.firstName !== '' && this.inputData.firstName !== undefined) this.inputData.firstNameInsensitive = this.inputData.firstName.toLowerCase()
        if (this.inputData.middleName !== '' && this.inputData.middleName !== undefined) this.inputData.middleNameInsensitive = this.inputData.middleName.toLowerCase()
        if (this.inputData.lastName !== '' && this.inputData.lastName !== undefined) this.inputData.lastNameInsensitive = this.inputData.lastName.toLowerCase()

        this.$store.dispatch('updateStory', { storyID: this.storyID, storyData: this.inputData })
        .then(() => {
          // Retrieve the updated story
          this.$store.dispatch('getStoryData', { storyID: this.storyID })
          .then((resp) => {
            this.storyData = resp
            this.loading = false
            this.isEditing = false
            this.isShowingReactToStoryModal = false
            window.scrollTo(0, 0)
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      unfavoriteStory () {
        const index = this.userData.favoriteStories.indexOf(this.storyID)
        if (index == -1) return
        this.userData.favoriteStories.splice(index, 1)
        this.$store.commit('SET_USERDATA', this.userData)
        this.$store.dispatch('updateUserData', { userUID: this.userUID })
        .then(() => {
          this.loading = false
          this.$forceUpdate()
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      }
    }
  }
</script>

<style scoped>
  .bg-blue {
    background-color: #90CAF9;
  }

  .bioHeaderGradient {
    background-image: linear-gradient(to top right, #90CAF9,#0D47A1 );
  }

  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .reaction-icon {
    border-radius: 10px;
    cursor: pointer;
    margin-right: 7px;
    padding: 10px;
  }
</style>
