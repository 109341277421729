<template>
  <div>
    <form @submit.prevent="submit">
      <v-row class="flex-wrap ma-0 pa-0" align="center">
        <!-- Select Award -->
        <v-col class="col-12 col-sm-9 ma-0 pa-0">
          <v-select
            v-model="award"
            :disabled="storyManagerID!=userUID"
            label="Select an Item"
            :items="require('@/assets/data/awards-any.json')"
            :menu-props="{ top: true, offsetY: true }"
            return-object
          />
        </v-col>
        <!-- Add Award  -->
        <v-col class="col-sm-3 ma-0 pa-0">
          <v-btn class="blue darken-3 ml-0 ml-sm-5 white--text" @click="addAward" :disabled="storyManagerID!=userUID">
            <i class="fas fa-plus mr-2"></i>Add Item
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-5 mt-sm-5 pa-0" align="center" justify="center">
        <!-- Award Details -->
        <v-btn class="blue darken-3 ml-sm-5 white--text" @click="handleUserClickedAwardDetails">
          <i class="fas fa-clipboard mr-2"></i>Award Details
        </v-btn>
      </v-row>
      <v-row class="ma-0 mt-5 mt-sm-5 pa-0" align="center" justify="center">
        <!-- Move Item Left -->
        <v-btn class="blue darken-3 ml-sm-5 white--text" @click="moveAward(true)" :disabled="storyManagerID!=userUID">
          <i class="fas fa-arrow-left mr-2"></i>Move Item Left
        </v-btn>
        <!-- Toggle Flag -->
        <v-btn v-if="isDeceased" class="blue darken-3 ma-5 ma-sm-0 ml-sm-5 white--text" @click="toggleFlagPosition" :disabled="storyManagerID!=userUID">
          <i class="fas fa-flag mr-2"></i>Toggle Flag
        </v-btn>
        <!-- Move Item Right -->
        <v-btn class="blue darken-3 ml-sm-5 white--text" @click="moveAward(false)" :disabled="storyManagerID!=userUID">
          Move Item Right<i class="fas fa-arrow-right ml-2"></i>
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShadowboxControls',

  props: ['isDeceased', 'storyManagerID'],

  data () {
    return {
      award: '',
      isFlagInner: false,
    }
  },

  computed: {
    ...mapGetters([
      'userUID'
    ])
  },

  methods: {
    addAward () {
      if (this.award) {
        this.$emit('addAward', this.award)
        this.award = ''
      }
    },

    moveAward (moveLeft) {
      this.$emit('moveAward', moveLeft)
    },

    toggleFlagPosition () {
      this.$emit('toggleFlagPosition', this.isFlagInner)
    },

    handleUserClickedAwardDetails() {
      this.$emit('viewAwardDetails')
    }
  }
}
</script>

<style lang="scss" scoped>
  .control-button {
    flex-grow: 1;
  }
</style>
