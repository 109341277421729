<template>
  <v-container class="ma-0 pa-0">
    <!-- Add Chapter -->
    <v-form v-if="isAuth"
      @submit.prevent="submit"
      ref="form"
      v-model="valid"
      class="mb-10 mt-10"
    >
      <h2 class="htblue">Add a Chapter</h2>
      <v-img v-if="isBirthdayToday && !isBirthdayChapter" class="pointer self-center" @click="isBirthdayChapter=true" src="@/assets/img/reaction-cake.png"></v-img>
      <v-img v-if="isBirthdayToday && isBirthdayChapter" class="outline pointer self-center" @click="isBirthdayChapter=false" src="@/assets/img/reaction-cake.png"></v-img>
      <v-textarea class="" rows="5" v-model="chapterDescription" placeholder="Share your thoughts..."></v-textarea>
      <v-btn
        type="submit"
        :loading="loading"
        class="blue darken-3 mb-3 white--text"
      >
        Submit
      </v-btn>
    </v-form>
    <v-row v-else justify="center" class="ma-0 pa-0 w-100">
      You need to be logged in to add a chapter to this story.<router-link to="/login" class="link ml-3">Log in</router-link>
    </v-row>
    <!-- Chapter Content -->
    <v-row v-for="(chapter, index) in sortedChapters" :key="index" class="ma-0 pa-0 w-100">
      <v-col v-if="chapter" align="left" class="col-12 ma-0 pa-0">
        <v-card class="blue lighten-5 mb-5 pa-5">
          <v-img v-if="chapter.avatarURL" class="" :src="chapter.avatarURL" max-width="150"></v-img>
          <h3 class="">{{ chapter.author }}</h3>
          <p class="">{{ new Date(chapter.time).toDateString() }}</p>
          <v-img v-if="chapter.isBirthdayChapter" src="@/assets/img/reaction-cake-l.png"></v-img>
          <p class="">{{ chapter.description }}</p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'StoryChapters',

    props: ['storyID', 'chapters'],

    data () {
      return {
        chapterDescription: '',
        error: null,
        isBirthdayChapter: false,
        isBirthdayToday: false,
        loading: false,
        sortedChapters: [],
        valid: false
      }
    },
    
    computed: {
      ...mapGetters([
        'isAuth',
        'userData'
      ])
    },

    created () {
      this.sortChapters(true)
    },

    methods: {
      sortChapters (reverse) {
        const reversal = reverse ? -1 : 1
        this.sortedChapters = [].concat(this.chapters)
        this.sortedChapters.sort((item1, item2) => {
          return item1.time < item2.time ? -1 * reversal : 1 * reversal
        })
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          const chapters = [
            ...(this.chapters || []),
            {
              author: this.userData.firstName + ' ' + this.userData.lastName,
              avatarURL: this.userData.avatar || '',
              description: this.chapterDescription,
              isBirthdayChapter: this.isBirthdayChapter,
              time: new Date().getTime()
            }
          ]
          this.$store.dispatch('updateStory', { storyID: this.storyID, storyData: { chapters: chapters } })
          .then(() => {
            // Retrieve the updated story
            this.$store.dispatch('getStoryData', { storyID: this.storyID })
            .then((resp) => {
              this.chapters = resp.chapters
              this.sortChapters(true)
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
              this.error = err
            })
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
