<template>
  <div class="shadowboxWidth mt-10" style="">
    <div v-if="isDeceased && !isFlagInner" class="pointer">
      <v-img @click="handleUserClickedAward('flag')" src="@/assets/img/shadowbox-flag.png" width="400"></v-img>
    </div>
    <div class="black htshadowbox">
      <v-img v-if="isDeceased && isFlagInner" @click="handleUserClickedAward('flag')" src="@/assets/img/shadowbox-flag.png" class="innerflag"></v-img>
      <!-- Awards -->
      <v-row class="flex-wrap items-start justify-center ma-0 pa-0 w-100" :class="{ 'awardMarginTop': isFlagInner }">
        <div v-for="(award, index) in awards" :key="index" style="border-radius: 10px; width: 15%;" :class="{ 'awardLarge': isFlagInner, 'awardMarginLeft': isFlagInner && index===0 && awards.length > 3, 'bg-blue': selectedAward===award}">
          <v-img @click="handleUserClickedAward(award)" class="award" style="cursor: pointer;" :src="require('@/assets/img/awards/' + award.key + '.png')"></v-img>
        </div>
      </v-row>
      <!-- Rank Insignia and Plaque -->
      <v-row v-if="rankInsigniaURL||plaque" align="center" class="items-center ma-0 mt-5  pa-0 w-100">
        <v-col class="col-3 ma-0 pa-0 pointer">
          <v-img v-if="rankInsigniaURL"
            @click="handleUserClickedAward(rankInsigniaURL, true)"
            :src="require('@/assets/img/rank-insignia/' + rankInsigniaURL + '.png')"
            class="ma-1"
            style="width: 35%;"
          ></v-img>
        </v-col>
        <v-col class="col-6 ma-0 pa-0">
          <v-card @click="isShowingPlaqueModal=true; inputPlaque=[]" class="plaqueBG" style="width: 75%" outlined>
            <div class="">{{ plaque[0] || 'FIRST PLAQUE LINE' }}</div>
            <div class="">{{ plaque[1] || 'SECOND PLAQUE LINE' }}</div>
            <div class="">{{ plaque[2] || 'THIRD PLAQUE LINE' }}</div>
          </v-card>
        </v-col>
        <v-col class="col-3 ma-0 pa-0 pointer">
          <v-img v-if="rankInsigniaURL"
            @click="handleUserClickedAward(rankInsigniaURL, true)"
            :src="require('@/assets/img/rank-insignia/' + rankInsigniaURL + '.png')"
            max-width="100"
            class="ma-5"
            style="width: 35%;"
          ></v-img>
        </v-col>
      </v-row>
      <!-- Award Dialog -->
      <v-dialog v-model="isShowingAwardModal" width="500"> 
        <v-card align="center" justify="center" class="pa-5">
          <!-- Header -->
          <v-card-title>{{ selectedAward.text }}</v-card-title>
          <v-img v-if="selectedAward" :src="require('@/assets/img/awards/' + selectedAward.key + '.png')" max-width="100" class="ma-4"></v-img>
          <v-card-text>
            {{ selectedAward.description }}
          </v-card-text>
          <v-card-actions>
            <v-col class="ma-0 pa-0">
              <!-- Upload Supporting Documents -->
              <v-row class="ma-0 pa-0" v-if="userUID===storyManagerID">
                <v-file-input
                  v-model="uploadFile"
                  accept="application/pdf"
                  label="Add supporting document"
                >
                </v-file-input>
                <v-btn
                  @click="uploadSupportingDocument"
                  :loading="loading"
                  color="blue darken-3 white--text"
                  class="ml-3"
                  :disabled="!uploadFile"
                >
                  Upload
                </v-btn>
              </v-row>
              <!-- List of Supporting Documents -->
              <v-row align="center" justify="end" v-for="(doc, i) in selectedAward.docs" :key="i" class="ma-0 mb-3 pa-0">
                <a :href="doc.url" target="_blank">{{ doc.name }}</a>
                <v-btn v-if="userUID===storyManagerID" @click="selectedAwardDocument=doc; isShowingConfirmDeleteAwardDocumentModal=true" class="red darken-1 ml-3 white--text">
                  <i class="fas fa-trash"></i>
                </v-btn>
              </v-row>
              <!-- Award Controls -->
              <v-row class="ma-0 pa-0">
                <v-btn v-if="userUID===storyManagerID"
                  @click="isShowingConfirmDeleteAwardModal=true"
                  :loading="loading"
                  color="red white--text"
                  class="mr-3"
                >
                  Delete Award
                </v-btn>
                <v-btn
                  :disabled="loading"
                  @click="isShowingAwardModal=false"
                >
                  Cancel
                </v-btn>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Delete Award Dialog -->
      <v-dialog v-model="isShowingConfirmDeleteAwardModal" width="500">
        <v-card>
          <v-card-title>Delete Award</v-card-title>
          <v-card-text>Are you sure you want to delete this award?</v-card-text>
          <v-card-actions>
            <v-btn
              @click="isShowingConfirmDeleteAwardModal=false; isShowingAwardModal=false; deleteSelectedAward()"
              :loading="loading"
              color="red white--text"
            >
              <i class="fas fa-trash-alt mr-2" style="color: white;"></i>
              Delete
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingConfirmDeleteAwardModal=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Delete Award Document Dialog -->
      <v-dialog v-model="isShowingConfirmDeleteAwardDocumentModal" width="500">
        <v-card>
          <v-card-title>Delete Award Document</v-card-title>
          <v-card-text>Are you sure you want delete <b>{{ this.selectedAwardDocument.name }}</b> from this award's documents?</v-card-text>
          <v-card-actions>
            <v-btn
              @click="isShowingConfirmDeleteAwardDocumentModal=false; deleteSelectedAwardDocument()"
              :loading="loading"
              color="red white--text"
            >
              <i class="fas fa-trash-alt mr-2" style="color: white;"></i>
              Delete
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingConfirmDeleteAwardDocumentModal=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Not Stolen Valor Dialog -->
      <v-dialog v-model="isShowingConfirmNotStolenValorModal" width="500" persistent>
        <v-card>
          <v-card-title>Stolen Valor</v-card-title>
          <v-card-text>I confirm that any items placed into this shadowbox are not stolen valor.</v-card-text>
          <v-card-actions>
            <v-btn
              @click="userData.isNotStolenValorConfirmed=true; isShowingConfirmNotStolenValorModal=false"
              color="red white--text"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Plaque Dialog -->
      <v-dialog v-model="isShowingPlaqueModal" width="500">
        <v-card align="center" justify="center" class="pa-5">
          <v-card-title>Shadowbox Plaque</v-card-title>
          <v-card-subtitle class="mt-5">
            Add up to three lines of information to your plaque. Maximum 30 characters per line.
            <v-text-field placeholder="FIRST LINE" v-model="inputPlaque[0]" maxlength="30"></v-text-field>
            <v-text-field placeholder="SECOND LINE" v-model="inputPlaque[1]" maxlength="30"></v-text-field>
            <v-text-field placeholder="THIRD LINE" v-model="inputPlaque[2]" maxlength="30"></v-text-field>
          </v-card-subtitle>
          <v-card-text>
            <v-card class="plaqueBG" style="font-size: 16px; width: 75%" outlined>
              <div class="pa-2">{{ inputPlaque[0] || 'FIRST LINE' }}</div>
              <div class="pa-2">{{ inputPlaque[1] || 'SECOND LINE' }}</div>
              <div class="pa-2">{{ inputPlaque[2] || 'THIRD LINE' }}</div>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="submitPlaque"
              :loading="loading"
              color="red white--text"
            >
              Submit
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingPlaqueModal=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Shadowbox',

    props: ['awards', 'serviceTerms', 'storyID', 'storyManagerID', 'plaque', 'isDeceased'],

    data () {
      return {
        error: '',
        inProgress: false,
        inputPlaque: [],
        isEditingAwardDetails: false,
        isFlagInner: false,
        isShowingAwardModal: false,
        isShowingConfirmDeleteAwardModal: false,
        isShowingConfirmDeleteAwardDocumentModal: false,
        isShowingConfirmNotStolenValorModal: false,
        isShowingPlaqueModal: false,
        isViewingAwardDetails: false,
        loading: false,
        photoFile: null,
        pdfFileUrl: null,
        selectedAward: '',
        selectedAwardDocument: '',
        showPDFUPload: false,
        uploadFile: null
      }
    },

    computed: {
      ...mapGetters([
        'userData',
        'userUID'
      ]),
      isDisabled: function () {
        return this.photoFile === null
      },
      // Finds the most recent service term and returns the URL of the corresponding rank insignia
      rankInsigniaURL: function () {
        if (!this.serviceTerms || this.serviceTerms.length === 0) return null

        var mostRecentServiceIndex = -1
        for (var i = 0; i < this.serviceTerms.length; i++) {
          if (mostRecentServiceIndex === -1 || this.serviceTerms[i].servedTo > this.serviceTerms[mostRecentServiceIndex].servedTo) {
            mostRecentServiceIndex = i
          }
        }

        if (mostRecentServiceIndex > -1) {
          const insigniaURL = this.serviceTerms[mostRecentServiceIndex].rank.insigniaURL

          return insigniaURL
        } else {
          return null
        }
      }
    },

    created () {
      this.isShowingConfirmNotStolenValorModal = (this.userData.isNotStolenValorConfirmed !== true && this.storyManagerID === this.userUID)
    },

    methods: {
      deleteSelectedAward () {
        this.$emit('deleteAward', this.selectedAward)
      },

      deleteSelectedAwardDocument () {
        this.loading = true
        this.$store.dispatch('deleteFile', { path: this.selectedAwardDocument.path })
        .then(() => {
          // Delete the doc from the story data
          const index = this.selectedAward.docs.indexOf(this.selectedAwardDocument)
          this.selectedAward.docs.splice(index, 1)
          this.updateStory()
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      editAwardDetails () {
        this.isEditingAwardDetails = true
        this.$nextTick(() => {
          this.$refs.inputDetails.focus()
          this.$refs.inputDetails.select()
        })
      },

      handleUserClickedAward (award, isRank = false) {
        this.selectedAward = this.awards[this.awards.indexOf(award)]
        if (award === 'flag') {
          this.selectedAward = {
            value: 'Tri-Fold American Flag',
            key: 'shadowbox-flag',
            description: 'The National Flag placed in a Shadow Box symbolizes that our country benefited from the loyal service of the recipient of the shadow box. United States has a special way of folding a flag that is reserved for the county alone. For Shadow Boxes, it is typical that flags are folded in the appearance of a tri-cornered hat, symbolic of the hats worn by colonial soldiers during the War of Independence.'
          }
        } else if (isRank) {
          this.selectedAward = this.serviceTerms[0].rank
          this.selectedAward.isRank = true
        }
      },

      selectAward (award) {
        if (!this.awards) return null
        for (var i = 0; i < this.awards.length; i++) {
          if (this.awards[i].key === award.key) {
            this.selectedAward = this.awards[i]
            return award
          }
        }
      },

      async submitAwardDetails () {
        this.awards[this.selectedAward.key] = this.selectedAward
        this.submit()
      },

      async submit () {
        /**
          * TODO: This section needs to be changed to add official documents to an ARRAY.
          * Then the documents will be shown in a list, with the ability to delete each one.
          * Currently, when a user uploads a different document, the original one is not deleted.
          */
        /* firebase
          .firestore()
          .collection('stories')
          .doc(this.storyID)
          .update({
            awards: this.awards
          })
          .then((resp) => {
            if (resp !== null) {
              this.$store.dispatch('stories/getManagedStories', { userUID: this.userUID }).then(() => {
                this.inProgress = false
                this.showPDFUPload = false
                this.photoFile = null
                this.pdfurl = null
                this.isEditingAwardDetails = false
              })
            }
          })
          .catch((error) => {
            console.error(error)
          }) */
      },

      submitPlaque () {
        this.isShowingPlaqueModal = false
        this.inputPlaque[0] = this.inputPlaque[0] || ''
        this.inputPlaque[1] = this.inputPlaque[1] || ''
        this.inputPlaque[2] = this.inputPlaque[2] || ''
        this.$emit('savePlaque', this.inputPlaque)
      },

      toggleFlagPosition () {
        this.isFlagInner = !this.isFlagInner
      },

      updateStory () {
        this.$store.dispatch('updateStory', { storyID: this.storyID, storyData: { awards: this.awards } })
        .then(() => {
          this.loading = false
          this.uploadFile = null
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      uploadSupportingDocument () {
        this.loading = true
        const file = this.uploadFile
        const path = 'awardDocs/' + this.storyID + '/' + file.name
        this.$store.dispatch('uploadFile', { file, path })
        .then((url) => {
          this.loading = false
          if (!this.selectedAward.docs) this.selectedAward.docs = []
          this.selectedAward.docs.push({
            name: file.name,
            path: path,
            url: url
          })
          // Save award data with new doc data
          this.updateStory()
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      viewAwardDetails () {
        if (this.selectedAward) {
          this.uploadFile = null
          this.isShowingAwardModal = true
        }
      }
    }
  }
</script>

<style scoped>
  .award {
    margin: 15px;
  }

  .awardLarge {
    width: 20%;
  }

  .awardMarginLeft {
    margin-left: 175px !important;
  }

  .awardMarginTop {
    margin-top: -200px !important;
  }

  .bg-blue {
    background-color: #1565C0;
  }

  .htshadowbox {
    border: 40px solid transparent;
    border-image: url("../assets/img/shadowbox-frame.png") stretch;
    border-image-slice: 10%;
    padding: 20px;
  }

  .innerflag {
    left: -306px;
    top: -70px;
    transform: rotate(-45deg);
    width: 400px;
  }

  .plaqueBG {
    background-image: linear-gradient(to bottom right, #FF6F00, #FFE082);
    padding: 10px;
  }

  .shadowboxWidth {
    width: 800px;
  }

  @media only screen and (max-width: 600px) {
    .award {
      margin: 5px;
    }

    .awardMarginLeft {
      margin-left: 17px !important;
    }

    .awardMarginTop {
      margin-top: -30px !important;
    }

    .htshadowbox {
      border: 10px solid transparent;
      border-image: url("../assets/img/shadowbox-frame.png") stretch;
      border-image-slice: 10%;
      padding: 5px;
    }

    .innerflag {
      left: -131px;
      top: -23px;
      transform: rotate(-45deg);
      width: 100px;
    }

    .plaqueBG {
      font-size: 5px;
      padding: 2px;
    }

    .shadowboxWidth {
      width: 100%;
    }
  }
</style>
