<template>
  <v-row>
    <v-col>
      <div class="error--text ma-5">{{ error }}</div>
      <!-- Upload Form -->
      <v-form
        @submit.prevent="submit"
        ref="form"
        v-model="valid"
      >
        <v-row v-if="isEditable" class="ma-0 w-100">
          <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
          <v-col class="col-12 col-sm-4 pa-0">
            <!-- Image Input -->
            <v-file-input v-if="isShowingUpload"
              v-model="previewImageFiles"
              @change="previewImages"
              label="Browse..."
              filled
              prepend-icon="mdi-camera"
              accept="image/*"
              multiple
            ></v-file-input>
            <!-- Controls -->
            <v-btn v-if="isShowingUpload"
              type="submit"
              :loading="loading"
              :disabled="previewImageFiles.length===0"
              class="blue darken-3 white--text"
            >
              Upload
            </v-btn>
            <v-btn v-if="isShowingUpload"
              @click="isShowingUpload=false"
              :disabled="loading"
              class="blue darken-3 ml-5 white--text"
            >
              Cancel
            </v-btn>
            <v-btn v-else
              @click="isShowingUpload=true; previewImageFiles=[]; previewImageURLs=[]"
              class="blue darken-3 white--text"
            >
              Upload Images
            </v-btn>
          </v-col>
          <v-col class="col-sm-4 d-none d-sm-flex"></v-col>
        </v-row>
        <!-- Image Previews -->
        <v-row v-if="isShowingUpload" justify="center" class="ma-0 mt-5 w-100">
          <v-card @click="removeSelectedImage(i)" v-for="(imageFile, i) in previewImageFiles" :key="i" shaped class="ma-2">
            <v-img :src="previewImageURLs[i]" max-width="82" max-height="82">
              <i class="fas fa-trash float-end ma-2" style="color: red"></i>
            </v-img>
          </v-card>
        </v-row>
      </v-form>
      <!-- Image Gallery -->
      <Stack :column-min-width="200" :gutter-width="4" :gutter-height="2" monitor-images-loaded class="mt-5">
        <StackItem v-for="(img, index) in storyData.photoAlbum" :key="index" style="cursor: pointer; transition: transform 300ms">
          <img :src="img.url" @click="selectedImage=img; isShowingImageModal=true" width="100%"/>
        </StackItem>
      </Stack>
      <!-- Image Dialog -->
      <v-dialog v-model="isShowingImageModal" width="500">
        <v-card color="grey darken-4">
          <v-img :src="selectedImage.url" max-width="100%"></v-img>
          <v-card-actions v-if="isEditable">
            <v-btn color="grey" @click="isShowingDeleteModal=true">
              <i class="fas fa-trash-alt" style="color: white;"></i>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Delete Image Dialog -->
      <v-dialog v-model="isShowingDeleteModal" width="500">
        <v-card>
          <v-card-title>Delete Image</v-card-title>
          <v-card-text>Are you sure you want to delete this image?</v-card-text>
          <v-card-actions>
            <v-btn
              @click="deleteImage"
              :loading="loading"
              color="red white--text"
            >
              <i class="fas fa-trash-alt mr-2" style="color: white;"></i>
              Delete
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingDeleteModal=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import { Stack, StackItem } from 'vue-stack-grid'

  export default {
    name: 'PhotoAlbumTiled',

    props: { storyID: String, storyData: Object, isEditable: Boolean },

    components: {
      Stack,
      StackItem
    },

    data () {
      return {
        error: null,
        isShowingDeleteModal: false,
        isShowingImageModal: false,
        isShowingUpload: false,
        loading: false,
        previewImageFiles: [],
        previewImageURLs: [],
        selectedImage: '',
        valid: false
      }
    },

    methods: {
      deleteImage () {
        this.loading = true
        this.$store.dispatch('deleteFile', { path: this.selectedImage.filePath })
        .then(() => {
          // Delete the photo from the story
          this.storyData.photoAlbum.splice(this.storyData.photoAlbum.indexOf(this.selectedImage), 1)
          this.updateStory()
          this.loading = false
          this.isShowingDeleteModal = false
          this.isShowingImageModal = false
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      previewImages () {
        for (var i = 0; i < this.previewImageFiles.length; i++) {
          this.previewImageURLs.push(URL.createObjectURL(this.previewImageFiles[i]))
        }
      },

      removeSelectedImage (index) {
        this.previewImageFiles.splice(index, 1)
        this.previewImageURLs.splice(index, 1)
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          var promises = []
          var img
          for (var i = 0; i < this.previewImageFiles.length; i++) {
            img = this.previewImageFiles[i]
            img.path = 'storyImages/' + this.storyID + '/photoAlbum/' + img.name
            promises.push(this.$store.dispatch('uploadFile', ({ file: img, path: img.path })))
          }

          Promise.all(promises)
          .then((urls) => {
            if (!this.storyData.photoAlbum) this.storyData.photoAlbum = []
            for (i = 0; i < this.previewImageFiles.length; i++) {
              this.storyData.photoAlbum.push({
                filePath: this.previewImageFiles[i].path,
                url: urls[i]
              })
            }
            this.updateStory()
            this.previewImageFiles = []
            this.isShowingUpload = false
          })
          .catch((err) => {
            this.error = err
            this.loading = false
            this.previewImageFiles = []
          })
        }
      },

      updateStory () {
        this.$store.dispatch('updateStory', { storyID: this.storyID, storyData: this.storyData })
        .then(() => {
          // Retrieve the updated story
          this.$store.dispatch('getStoryData', { storyID: this.storyID })
          .then((resp) => {
            this.storyData = resp
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      }
    }
  }
</script>

<style scoped>
  
</style>
